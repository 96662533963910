<template>
  <div>
		<div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/recordAbnormal' }">异常记录</el-breadcrumb-item>
        <el-breadcrumb-item>异常记录详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
		<div class="form-box">
      <el-form :model="form" ref="ruleForm" label-width="120px">
        <div class="form-title">巡检信息</div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="巡检点名称：" prop="name">{{ form.patrolName }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="位置名称：" prop="name">{{ form.facilityName }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="巡检代号：" prop="name">{{ form.patrolNumber }}</el-form-item>
          </el-col>
        </el-row>

        <div class="form-title">设备信息</div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备名称：">{{ form.deviceVO.archivesName }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格参数：">{{ form.deviceVO.specification }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="所属部门：">{{ form.deviceVO.departmentName }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备类型：">{{ form.deviceVO.deviceTypeName }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备别名：">{{ form.deviceVO.alias }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="投入使用日期：" prop="name">{{ form.deviceVO.applyTime? dayjs(form.applyTime).format('YYYY-MM-DD HH:mm:ss'):'' }}</el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备状态：">{{ form }}</el-form-item>
          </el-col>
        </el-row> -->
        <!-- <div class="form-title">检查内容</div>
        <div v-for="(item, i) in form.deviceContent" :key="i">
          {{i + 1}}检查内容：{{item.checkContent}} <el-checkbox v-model="item.checkResult">否</el-checkbox><el-checkbox v-model="item.checkResult">是</el-checkbox>
        </div> -->
        
        <div class="form-title">问题文字描述</div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="文字描述：">{{ form.describe }}</el-form-item>
          </el-col>
        </el-row>
        <div class="form-title">检查内容</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item v-for="(item, i) in form.deviceContent" :key="i" :label="i + 1 +'.检查内容：'">{{ item.checkContent }}</el-form-item>
            </el-col>
          </el-row>
        <div class="form-title">巡点检图片文件</div>
        <div>
          <el-image 
            v-for="item in form.imageUrls"
            :key="item"
            style="width: 100px; height: 100px"
            :src="item" 
            :preview-src-list="[item]">
          </el-image>
        </div>
        <!-- <div class="form-title">巡点检视频文件</div>
        <div class="form-title">巡点检语音文件</div> -->

      </el-form>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  data() {
    return {
      form: {
        taskDeviceItems: []
      },
			row: {},
      data: {},
      tableData: []
    };
	},
	methods: {
    dayjs,
		loadData() {
      this.$ajax.post("recordAbnormalDetail", {
        archivesId: this.row.archivesId,
        patrolDeviceId: this.row.patrolDeviceId,
        patrolId: this.row.patrolId,
        taskDeviceId: this.row.taskDeviceId,
        taskId: this.row.taskId
      }).then((res) => {
        this.form = res.data
        this.tableData = res.data.taskDeviceItems
      });
    },
	},
	mounted() {
    if (this.$route.params.row == null) {
			this.$router.push('/recordAbnormal')
		} else {
			this.row = this.$route.params.row
    }
    this.loadData()
  },
}
</script>

<style lang="less" scoped>

</style>